import { db } from "@/main";
export default {
  state: {
    programs: [],
    program: {
      name: "",
      description: "",
      createdAt: "",
      missionIds: [],
    },
    missionsOfProgram: [],
  },
  mutations: {
    setPrograms(state, payload) {
      state.programs = payload;
    },
    addToPrograms(state, payload) {
      state.programs.unshift(payload);
    },
    setProgram(state, payload) {
      state.program = payload;
    },
    addMissionsToProgram(state, payload) {
      state.missionsOfProgram = payload;
    },
    updateMissionsOfProgram(state, payload) {
      state.missionsOfProgram = payload;
    },
  },
  actions: {
    fetchPrograms({ commit }) {
      const docRef = db.collection("programs").orderBy("createdAt", "desc");
      docRef
        .get()
        .then((querySnapshot) => {
          var programs = [];
          querySnapshot.forEach(function(doc) {
            var item = doc.data();
            item["id"] = doc.id;
            programs.push(item);
          });
          commit("setPrograms", programs);
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    fetchProgram({ dispatch, commit }, payload) {
      commit("setLoading", true);
      commit("addMissionsToProgram", []);
      const docRef = db.collection("programs").doc(payload.id);
      docRef
        .get()
        .then((doc) => {
          var item = doc.data();
          item["id"] = doc.id;
          if (item.missionIds && item.missionIds.length > 0) {
            dispatch("fetchMissionsOfProgram", item.missionIds);
          } else {
            commit("setLoading", false);
          }
          if (payload.type == "addToCollection") {
            commit("addToPrograms", item);
          } else {
            commit("setProgram", item);
          }
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    createProgram({ dispatch, commit }, payload) {
      commit("setLoading", true);
      const docRef = db.collection("programs");
      docRef
        .add(payload)
        .then((res) => {
          dispatch("fetchProgram", { id: res.id, type: "addToCollection" });
          commit("setError", null);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");

          commit("setLoading", false);
        });
    },
    deleteProgram({ commit }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        const docRef = db.collection("programs").doc(payload.id);
        docRef
          .delete()
          .then(() => {
            commit("setSnack", "Nutzergruppe wurde gelöscht!");
            commit("setLoading", false);
            commit("setError", null);
            resolve();
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            commit("setSnack", "");
            reject();
            commit("setLoading", false);
          });
      });
    },
    fetchMissionsOfProgram({ commit }, missionIds) {
      let itemRefs = missionIds.map((id) => {
        return db
          .collection("missions")
          .doc(id)
          .get();
      });
      Promise.all(itemRefs)
        .then((docs) => {
          let items = docs.map((doc) => {
            if (doc.exists) return doc.data();
          });
          commit("addMissionsToProgram", items);
          commit("setLoading", false);
          commit("setError", null);
        })
        .catch((error) => {
          console.log(error);
          commit("setSnack", "");
          commit("setLoading", false);
        });
    },
    assignMissionsToProgram({ dispatch, commit }, data) {
      commit("setLoading", true);
      const docRef = db.collection("programs").doc(data.pid);
      docRef
        .set({ missionIds: data.missionIds }, { merge: true })
        .then(function(res) {
          dispatch("fetchMissionsOfProgram", data.missionIds);
          commit("setLoading", false);
          commit("setError", null);
          commit("setSnack", "Die Missionen im Programm wurden angepasst.");
        })
        .catch(function(error) {
          commit("setLoading", false);
          console.error("Error updating document: ", error);
          commit("setSnack", "");
        });
    },
  },
  getters: {
    programs(state) {
      return state.programs;
    },
    program(state) {
      return state.program;
    },
    missionsOfProgram(state) {
      return state.missionsOfProgram;
    },
  },
};
