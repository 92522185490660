<template lang="pug">
    v-dialog(v-model='addMissionDialog' width='650px')
      template(v-slot:activator='{ on }')
        v-btn.mb-3(text color="accent lighten-1" depressed large v-on='on' @click="searchMissions" :loading="loading" :disabled="loading") 
            v-icon(left) add
            span Mission
      v-card
        v-toolbar(color="primary" dark flat)
          v-toolbar-title Missionen hinzufügen
        v-card-text.mt-2 
          v-list-item-group(flat='' v-model='selectedMissions' multiple=''  subheader='' three-line='')
              v-list-item(v-for="(mission, index) in missions" :key="mission.id" :value="mission ? mission : index")
                template(v-slot:default='{ active, toggle }')
                  v-list-item-avatar
                    v-img(:src="mission.imageUrl")
                  v-list-item-content
                    v-list-item-title {{mission.name}}
                    v-list-item-subtitle {{mission.points}}P
                  v-list-item-action
                    v-checkbox(v-model='active' @click="toggle")
        v-card-actions
          v-btn(text @click="cancelDialog") Abbrechen
          v-spacer
          v-btn(color="primary" depressed @click="assignMissionToProgram") Hinzufügen
 

</template>
<script>
export default {
  data: () => ({
    addMissionDialog: false,
    selectedMissions: [],
  }),
  props: ["missionList", "programId"],

  computed: {
    missions() {
      if (this.missionList && this.missionList.length > 0) {
        var missions = this.$store.getters.missions.filter((event) => {
          const isSelected = this.missionList.some((el) => el.id === event.id);
          return !isSelected;
        });
        return missions;
      } else {
        return this.$store.getters.missions || [];
      }
    },
    missionsOfProgram: {
      get() {
        return this.$store.getters.missionsOfProgram;
      },
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    cancelDialog() {
      this.selectedMissions = [];
      this.addMissionDialog = false;
    },
    searchMissions() {
      this.$store.dispatch("fetchMissions");
    },
    assignMissionToProgram() {
      const ugid = this.$route.params.ugid;
      const pid = this.programId;

      const data = {
        ugid,
        pid,
        missions: this.selectedMissions,
      };

      this.$store.dispatch("addMissionsToProgramInUsergroup", data);
      this.addMissionDialog = false;
    },
  },
};
</script>
