<template lang="pug">
    v-dialog(v-model='addUserDialog' width='550px')
      template(v-slot:activator='{ on }')
        v-btn.mb-3(block color="accent" depressed large v-on='on'  :loading="loading" :disabled="loading") 
            v-icon(left) person_add
            span Teilnehmer hinzufügen
      v-card.mx-auto(tile='' class="d-flex flex-column")
        v-card-title Teilnehmer einladen
        v-card-text 
          v-text-field(outlined='' required autofocus v-model="email_textfield" type="email" label='E-Mail Adresse' @change="addToUserArray" @click:append-outer="addToUserArray" append-outer-icon="person_add")
        v-list(shaped='')
            v-list-item(two-line='' v-for='(user, i) in add_users' :key='i')
                v-list-item-avatar(color="accent")
                    v-icon(dark) mdi-account
                v-list-item-content
                    v-list-item-title(v-text='user')
                v-list-item-action
                    v-btn(icon @click="removeUserFromArray(i)")
                      v-icon(color="grey lighten-1") clear
        v-card-actions 
          v-spacer
          v-btn(text :disbaled="loading" @click="addUserDialog = false") Abbrechen
          v-btn(depressed color="primary" :loading="loading" :disabled="add_users.length < 1 || loading" @click="sendInvite") Einladen
        

</template>
<script>

export default {
    data: () => ({
            addUserDialog: false,
            email_textfield: "",
      add_users: [],
      valid: true,
   }),
   computed: {
    usersOfUsergroup() {
      return this.$store.getters.usersOfUsergroup;
    },
    usergroup() {
      return this.$store.getters.usergroup || null;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
      addToUserArray() {
        this.add_users.push(this.email_textfield)
        this.email_textfield = ""
      },
      removeUserFromArray(index) {
        this.add_users.splice(index, 1)
      },
      sendInvite() {
      var data = {
        ugid: this.$route.params.ugid,
        emails: this.add_users
      };
      this.$store.dispatch("addUserOfUsergroup", data);
      this.add_users = [];
      this.addUserDialog = false;
    },
    
  }
}
</script>