import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Activate from '../views/Activate.vue'
import Settings from '../views/Settings.vue'
import Messages from '../views/messages/messages.vue'
import Missions from '../views/missions/missions.vue'
import Employees from '../views/employees/employees.vue'
import Programs from '../views/programs/programs.vue'
import Program from '../views/programs/program.vue'
import Usergroups from '../views/users/usergroups.vue'
import Usergroup from '../views/users/usergroup.vue'
import Login from '../views/Login.vue'
import SetPassword from '../views/SetPassword.vue'
import CompleteMission from '../views/CompleteMission.vue'
import firebase from 'firebase/app'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: { name: 'login' }
  },
  {
    path: '/missions',
    name: 'missionen',
    component: Missions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employees',
    name: 'mitarbeiter',
    component: Employees,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/programs',
    name: 'programme',
    component: Programs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/programs/:pid',
    name: 'programm',
    component: Program,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usergroups',
    name: 'nutzergruppen',
    component: Usergroups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usergroups/:ugid',
    name: 'nutzergruppe',
    component: Usergroup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/messages',
    name: 'nachrichten',
    component: Messages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'einstellungen',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activate',
    name: 'activate',
    component: Activate
  },
  {
    path: '/complete-mission',
    name: 'complete-mission',
    component: CompleteMission
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/setpassword',
    name: 'setpassword',
    component: SetPassword
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !currentUser) next('/login');
  else if (!requiresAuth && currentUser) next();
  else next();
})

export default router
