<template>
  <div class="home">
     <v-toolbar flat>
          <v-toolbar-title ><strong>Calumis Akademie</strong>
              </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <v-main>
           <v-container>
      <!-- <v-img src="https://www.calumis.de/assets/Uploads/coaching-akademie-duesseldorf-slide-1.jpg"></v-img> -->
      <v-row>
        <v-col cols="12">
          <h2>Willkommen zur Calumis Akademie</h2>
        </v-col>
      </v-row>
      
    </v-container>
          </v-main>
        </v-card-text>
    
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {
    
  },
  data: () => ({
      
  }),
  methods: {
      
  },
}
</script>
<style></style>
