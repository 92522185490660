<template lang="pug">
    v-dialog(v-model='editUserDialog' width='550px')
      template(v-slot:activator='{ on }')
        v-btn.mb-3.moreBtn(icon small color="grey lighten-2" v-on='on' :loading="loading" :disabled="loading") 
            v-icon edit
      v-card.mx-auto(tile='' class="d-flex flex-column")
        v-tabs(fixed-tabs='' v-model="tab" color='primary')
          v-tab
            | Nutzer bearbeiten
          v-tab
            | Mails verschicken
        v-tabs-items(v-model="tab")
          v-tab-item
            v-card-text 
              v-text-field(outlined='' required v-model="user.username" type="text" label='Pseudonym')
              v-text-field(outlined='' required v-model="user.points" type="number" label='Punkte')
            
            v-card-actions 
              v-btn(text color="red" :disbaled="loading" @click="removeUserFromUsergroup") Nutzer entfernen
              v-spacer
              v-btn(text :disbaled="loading" @click="editUserDialog = false") Abbrechen
              v-btn(depressed color="primary" :loading="loading" @click="saveChanges") Speichern
          v-tab-item
            v-card-text 
              v-radio-group(v-model="emailType")
                v-radio(label="Pseudonym setzen" value="pseudonym")
                v-radio(label="Erinnerung an eine Mission" value="mission")
              div(v-if="emailType == 'mission'")
                v-select(:items="missionsOfUsergroup" v-model="program" clearable dense label="Wähle das Programm" :disabled="mission != null && program != null" return-object outlined)
                  template(slot="selection" slot-scope="data") {{data.item.name}}
                  template(slot="item" slot-scope="data") {{data.item.name}} ({{convertDate(data.item.assignedAt)}})
                v-select(:items="program.missions" v-model="mission" clearable dense label="Wähle die Mission aus" item-text="name" return-object outlined v-if="program != null")
                  template(slot="selection" slot-scope="data") {{data.item.name}}
                  template(slot="item" slot-scope="data") {{data.item.name}} ({{convertDate(data.item.createdAt)}})
              v-alert(v-if="emailType == 'pseudonym' || mission" color="primary" text icon="mdi-send" border="right" prominent outlined)
                span(v-if="emailType != 'pseudonym' && mission") Eine Erinnerung an die Mission '{{mission.name}}' kann jetzt verschickt werden
                span(v-if="emailType == 'pseudonym'") Eine Einladung zum setzten des Pseudonyms für '{{user.email}}' kann jetzt verschickt werden
            v-card-actions 
              v-spacer
              v-btn(text :disabled="loading" @click="editUserDialog = false") Abbrechen
              v-btn(depressed color="primary" :disabled="!emailType || (emailType == 'mission' && !mission)" :loading="loading" @click="sendMail") E-Mail senden
      ask-for-permission-dialog(v-model="deleteDialog")  

</template>
<script>
import AskForPermissionDialog from "@/components/AskForPermissionDialog.vue";
import moment from "moment";

export default {
  components: {
    AskForPermissionDialog,
  },
  props: ["user"],
  data: () => ({
    tab: 0,
    editUserDialog: false,
    valid: true,
    deleteDialog: {
      show: false,
    },
    emailType: null,
    program: null,
    mission: null,
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    missionsOfUsergroup() {
      return this.$store.getters.missionsOfUsergroup ?? [];
    },
  },
  methods: {
    saveChanges() {
      this.user.points = parseInt(this.user.points);
      const data = {
        ugid: this.$route.params.ugid,
        user: this.user,
      };
      this.$store.dispatch("editUserInUsergroup", data);
      this.editUserDialog = false;
    },
    removeUserFromUsergroup() {
      this.$store.dispatch("removeUserFromUsergroup", {
        ugid: this.$route.params.ugid,
        user: this.user,
      });
      this.editUserDialog = false;
    },
    sendMail() {
      const emailType = this.emailType;
      const missionId = this.mission?.id;
      const userId = this.user.id;
      const username = this.user.username;
      const programId = this.program?.id;
      const data = {
        userId,
        mid: missionId,
        emailType,
        ugid: this.$route.params.ugid,
        username,
        pid: programId,
      };
      if (emailType === "pseudonym") {
        this.$store.dispatch("sendMailToUpdateUsername", data);
      } else if (emailType === "mission") {
        this.$store.dispatch("sendReminderForMission", data);
      }

      this.mission = null;
      this.program = null;
      this.emailType = null;
      this.editUserDialog = false;
    },
    convertDate(createdAt) {
      if (createdAt) {
        moment.locale("de");
        return moment(createdAt.toDate()).format("l");
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.moreBtn
  margin-right: -5px
</style>
