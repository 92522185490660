<template lang="pug">
  div(id="programs")
    v-container
      v-row
        v-col(cols='12')
          div(v-if='error')
            v-card-title {{error}}
          create-new-program(v-else='')
        v-col(cols='12' md='4' v-for='(program, i) in programs' :key='program.id')
          v-card.mx-auto.d-flex.flex-column(tile='' :to="{ name: 'programm', params: { pid: program.id} }")
            v-card-title.headline {{program.name}}
            v-card-subtitle.flex-grow-1 {{program.description}}
            v-list.transparent
              v-divider.mt-3
              v-list-item
                v-list-item-title.text--disabled Erstellt am {{convertcreatedAt(program.createdAt)}}


</template>

<script>
import CreateNewProgram from "@/components/CreateNewProgramDialog.vue";
import moment from 'moment';
export default {
  name: "usergroups",
  components: {
    CreateNewProgram
  },
  data: () => ({}),
  computed: {
    programs() {
      return this.$store.getters.programs || [];
    },
    error() {
      return this.$store.getters.error;
    },
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    getPrograms() {
        this.$store.dispatch("fetchPrograms");
    },
    convertcreatedAt(createdAt) {
      moment.locale('de');
      return moment(createdAt.toDate()).format('lll');
    }
  }
};
</script>
<style lang="sass">
a.v-card 
  transition: all 0.4s ease
  &:hover
    transition: all 0.4s ease
    transform: scale(1.03)
</style>