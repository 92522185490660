export default {
    state: {
      loading: false,
      error: null,
      snack: {
        message: '',
        show: false
      }
    },
    mutations: {
      setLoading (state, payload) {
        state.loading = payload
      },
      setError (state, payload) {
        state.error = payload
      },
      clearError (state) {
        state.error = null
      },
      setSnack (state, snack) {
        if(snack == '') {
          state.snack = { show: true, message: 'Da ist leider etwas schiefgelaufen!' }
        }else{
          state.snack = { show: true, message: snack }
        }
      }
    },
    actions: {
      clearError ({commit}) {
        commit('clearError')
      },
      setError ({commit}, payload) {
        commit('setError', payload)
      }
    },
    getters: {
      loading (state) {
        return state.loading
      },
      error (state) {
        return state.error
      },
      snack (state) {
        return state.snack
      }
      
    }
  }
  