<template>
  <div>
    <v-card flat tile>
    <v-toolbar
      color="primary"
      dark
      flat
      shrink-on-scroll
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
    </v-toolbar>

    
  </v-card>
    
    <v-navigation-drawer
      v-model="drawer"
      v-if="user"
      app
      dark
      color="primary lighten-1"
      clipped
    >
      <workspace-navigation-drawer></workspace-navigation-drawer>
    </v-navigation-drawer>
    
    
    <v-app-bar clipped-left   app dark flat color="primary">
      <v-app-bar-nav-icon
        v-if="user"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="text-capitalize">{{$route.name}}</v-toolbar-title>

      <v-spacer></v-spacer>

      
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        offset-x
        v-if="user"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>

            <span v-if="user.displayName && !isMobile">{{ user.displayName }}</span>
            <span v-if="!user.displayName && !isMobile">{{user.email}}</span>
            <span v-if="isMobile && user.customClaims && user.customClaims.initials">{{user.customClaims.initials}}</span>
            <v-icon right>keyboard_arrow_down</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar size="48" :color="user.photoUrl ? 'transparent' : 'primary'">
                <img
                  v-show="user.photoUrl"
                  :src="user.photoUrl"
                  :alt="user.email"
                />
                <span v-if="user.customClaims && user.customClaims.initials" class="white--text headline has-text-centered">{{user.customClaims.initials}}</span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-if="user.displayName">{{ user.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn class="red--text" icon @click="onLogout">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <!-- <v-divider></v-divider>

          <v-list>
            <v-list-item link to="/settings">
              <v-list-item-action>
                <v-icon>settings</v-icon>
              </v-list-item-action>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item>
            
          </v-list> -->
        </v-card>
      </v-menu>
    </v-app-bar>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WorkspaceNavigationDrawer from "@/components/WorkspaceNavigationDrawer.vue";
// import firebase from "firebase/app";
export default {
  components: {
    WorkspaceNavigationDrawer
  },

  data: () => ({
    drawer: true,
    mini: false,
    darkMode: false,
    workspace: {},
    isMobile: window.innerWidth < 600
  }),
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
      if (this.$route.fullPath != "/login") {
        this.$router.push("/login");
      }
    },
    addWorkspaceToSelected(name) {
      this.$store.commit("addWorkspaceToSelected", name);
    },
    toggleTheme() {
      this.$vuetify.theme.dark = this.darkMode;
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    selectedWorkspace() {
      return this.$store.getters.selectedWorkspace;
    },
    hasWorkspaces() {
      if (this.user.workspaces && this.user.workspaces != null) {
        return true;
      }
      return false;
    },
    workspaceIdSelected() {
      return this.$route.params.workspaceId;
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    }
  },
  created() {
    this.darkMode = this.$vuetify.theme.dark;
  }
};
</script>
<style lang="scss" scoped>
// .v-avatar {
//   span {
//     padding-left: 0.5rem;
//     font-size: 1.3rem !important;
//   }
// }
</style>
