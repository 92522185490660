
import Vue from 'vue'
import Vuex from 'vuex'
import user from './usergroups'
import programs from './programs'
import employees from './employees'
import shared from './shared'
import missions from './missions'
import messages from './messages'
import settings from './settings'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    user,
    programs,
    employees,
    shared,
    missions,
    messages,
    settings
  }
});