<template lang="pug">
  div
    template
      v-list-item.pa-4
        img(src='~@/assets/logo-calumis.png' width='80%')
    v-divider
    v-list(dense='' v-if="user")
      v-subheader.mt-4
        | Navigation
      v-list-item(link='' v-for='link in navigation' :key='link.title' :to='{ name: link.to }')
        v-list-item-icon
          v-icon {{link.icon}}
        v-list-item-content
          v-list-item-title
            | {{link.title}}
      v-divider.my-4(inset   v-if="isAdmin()")
      v-list-item(link='' v-for='link in navigationMore' :key='link.title'  v-if="isAdmin()" :to='{ name: link.to }')
        v-list-item-icon
          v-icon {{link.icon}}
        v-list-item-content
          v-list-item-title
            | {{link.title}}

</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WorkspaceNavigationDrawer",
  data() {
    return {
      navigation: [
        {
          title: "Missionen",
          icon: "assignment",
          to: "missionen",
        },
        {
          title: "Programme",
          icon: "mdi-file-multiple",
          to: "programme",
        },
        {
          title: "Nutzergruppen",
          icon: "supervised_user_circle",
          to: "nutzergruppen",
        },

        {
          title: "Nachrichten",
          icon: "message",
          to: "nachrichten",
        },
      ],
      navigationMore: [
        {
          title: "Mitarbeiter",
          icon: "people",
          to: "mitarbeiter",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {},
  methods: {
    isAdmin() {
      if (this.user) {
        const claims = this.user.customClaims;
        if (claims) {
          const admin = claims.admin;
          return admin;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
