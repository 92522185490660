<template lang="pug">
  .setPassword.fill-height
    v-container.fill-height
      .overlay
      v-row(align='center', justify='center')
        v-col(md='6', sm='12')
          v-alert.mb-4(v-if='error', dismissible='')
            span(v-if='error.message') {{ error.message }}
            span(v-else='') {{ error }}
          v-card.elevation-12.px-8.py-12(color='white')
            v-toolbar(flat='')
              v-toolbar-title
                h2 Passwort setzen
                span Setze jetzt Dein neues Passwort
            v-form(ref='form', v-model='valid', @submit.prevent='setPassword', lazy-validation='')
              v-card-text
                v-text-field#password(v-model='password', :disabled="error" :rules='passwordRules', @click:append='show1 = !show1', :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'", label='Passwort', :type="show1 ? 'text' : 'password'", required='')
                v-text-field#password_repeat(v-model='password_repeat', :disabled="error" :rules='[passwordConfirmationRule]', @click:append='show2 = !show2', :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'", label='Passwort wiederholen', :type="show2 ? 'text' : 'password'", required='')
              v-card-actions
                v-spacer
                  v-btn.text-capitalize.px-4(color='primary',  rounded='', :disabled='!valid || loading || error', :loading='loading', type='submit') Speichern
    v-footer
      div &copy; {{ new Date().getFullYear() }} calumis

</template>
<script>
export default {
  name: "setpassword",
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    password: "",
    password_repeat: "",
    passwordRules: [
      v => !!v || "Bitte gib ein Passwort ein",
      v => (v && v.length >= 8) || "Mindesten 8 Zeichen"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    checkbox: false,
    lazy: false
  }),
  computed: {
    
    mode(){
      return this.$route.query.mode;
    },
    inviteToken(){
      return this.$route.query.inviteToken;
    },
    oobCode(){
      return this.$route.query.oobCode;
    },
    ctoken(){
      return this.$route.query.ctoken;
    },
    email(){
      return this.$route.query.email;
    },
    apiKey(){
      return this.$route.query.apiKey;
    },
    lang(){
      return this.$route.query.lang;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    passwordConfirmationRule() {
      return this.password === this.password_repeat || 'Passwort muss identisch sein'
    }
  },
  created() {
    if(!this.oobCode && !this.inviteToken){
      this.$store.commit('setError',  "Dieser Link ist leider ungülitig. Kontaktiere deinen Ansprechpartner.");
    }else{
      this.$store.commit('setError',  null);
    }
  },
  methods: {
    
    setPassword() {
      if(this.oobCode){
        // reset Password
        this.$store.dispatch("resetPassword", {
          password: this.password,
          code: this.oobCode,
          email: this.email
        });
      } else if(this.inviteToken){
        // set initial Password
        this.$store.dispatch("setPassword", {
          password: this.password,
          inviteToken: this.inviteToken,
          email: this.email
        });
      }else{
        this.$store.commit('setSnack', "Etwas ist schiefgelaufen. Öffne den Link erneut.")
      }
    },
  }
};
</script>

<style lang="scss">
.setPassword{
  background: url('~@/assets/calumis-background.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  margin: 0;
  padding: 0;

  .overlay{
    background: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .v-card{
    width: 100%
  }
  h2 {
    font-size: 2rem !important;
  }
  span{
    font-size: 1rem;
    a {
      text-decoration: none
    }
  }
  .fb-btn{
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
    color: #fff !important;
  }
  .google-btn{
    background-color: #fff !important;
    border-color: #eaeaea !important;
    color: #505050 !important;
  }
}
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #4b4b4b;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #4b4b4b;
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}

</style>
