<template lang="pug">
  v-card(class="mx-auto" max-width="600" )
    .activate
      v-toolbar(flat='' color='primary py-1' height='100')
        img.ml-8(src='~@/assets/logo-calumis.png' style='max-width: 250px;' alt='calumis logo')
  
      v-divider
      .activate.pt-8(v-if="!username")
        v-card-title(v-if='token && ugid && email') Bitte gib hier Dein Pseudonym an, unter dem Du im Ranking Deiner Gruppe erscheinst
        v-card-text.mb-4
          div(v-if='token && ugid && email')
            h2.title 
            v-text-field(v-model='created_username' autofocus :rules='rules.name' outlined color='primary darken-2' label='Pseudonym' required='')
          p(v-else='') Leider ist diese URL ung&uuml;ltig. Kontaktiere doch bitte Deinen Ansprechpartner.
        v-card-actions
          v-spacer
          v-btn(depressed color="primary lighten-1" @click="saveUsername" :loading="loading" v-if='token && ugid && email') Pseudonym speichern
      .activated(v-if="username")
        .d-flex.flex-no-wrap.justify-space-between
          div
            v-card-title.headline Willkommen {{username}}
            v-card-subtitle Bald bekommst Du Deine erste Mission!
          v-avatar.ma-3(size='64' tile='' color='success' )
            v-icon(dark='' size="50") check
        
        

   
</template>
<script>
export default {
  name: "activate",
  components: {},
  data: () => ({
    created_username: "",
    rules: {
      name: [
        (val) =>
          (val || "").length > 3 ||
          "Der Nutzername muss mindestens aus drei Zeichen bestehen",
      ],
    },
  }),
  computed: {
    token() {
      return this.$route.query.token;
    },
    ugid() {
      return this.$route.query.ugid;
    },
    email() {
      return this.$route.query.email;
    },
    username() {
      return this.$route.query.username;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    saveUsername() {
      var data = {
        token: this.token,
        ugid: this.ugid,
        email: this.email,
        username: this.created_username,
      };
      this.$store.dispatch("setUsernameOfUserOfUsergroup", data).then((res) => {
        //After successfull update
        if (res == "Pseudonym wurde erfolgreich gespeichert.") {
          var fullPath = this.$route.fullPath;
          fullPath = fullPath + "&username=" + this.created_username;
          this.$router.push(fullPath);
        }
      });
    },
  },
};
</script>
<style>
.v-toolbar {
  border-radius: 4px;
}
</style>
