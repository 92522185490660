<template lang="pug">
  div
    v-container
      ask-for-permission-dialog(v-model="deleteDialog")
      v-row
        v-col(cols='12')
          div(v-if='error')
            v-card-title {{error}}
          create-new-mission(v-else='')
        v-col(cols='12' sm='6' md='4' v-for='(mission, i) in missions' :key='mission.id')
          v-card.mx-auto.d-flex.flex-column(height='100%')
            v-img.white--text.align-start.pt-4.pl-4.flex-grow-0(contain lazy-src="~@/assets/lazy-loading-tiny.jpg" :src='mission.imageUrl')
              
              template(v-slot:placeholder='')
                v-row.fill-height.ma-0(align='center' justify='center')
                  v-progress-circular(indeterminate='' color='grey lighten-5')
              
            v-card-title.flex-grow-1.align-start {{mission.name}}
            v-card-subtitle.flex-grow-1.align-start {{mission.backOfficeName}}
            v-divider.mx-4.flex-grow-0
            v-card-actions.flex-grow-0
              v-chip(dark='' color='accent')
                strong {{mission.points || 0}}
                span P
              v-spacer
              create-new-mission-dialog( :editMission="mission")
              v-btn(icon='')
                v-icon(color='grey darken-1' @click="removeMission(mission)") delete

</template>

<script>
import CreateNewMission from "@/components/CreateNewMissionDialog.vue";
import AskForPermissionDialog from "@/components/AskForPermissionDialog.vue";
import CreateNewMissionDialog from "@/components/CreateNewMissionDialog.vue";
export default {
  name: "missions",
  components: {
    CreateNewMission,
    AskForPermissionDialog,
    CreateNewMissionDialog
  },
  data: () => ({
     deleteDialog: {
      show: false
    },
  }),
  computed: {
    missions() {
      return this.$store.getters.missions ?? [];
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  mounted() {
    this.getMissions();
  },
  methods: {
    getMissions() {
        this.$store.dispatch("fetchMissions");
    },
    removeMission(mission){
      this.deleteDialog = {
        show: true,
        text: "Möchtest Du die Mission '" + mission.name + "' wirklich löschen? Diese wird dann auch aus allen Programmen entfernt.",
        accepted: false,
        button: "Löschen",
        mission: mission
      };
      
    },
    editMission(mission){
      this.deleteDialog = {
        show: true,
        text: "Möchtest Du die Mission '" + mission.name + "' wirklich bearbeiten? Diese wird dann auch aus allen Programmen entfernt.",
        accepted: false,
        button: "Löschen",
        mission: mission
      };
      
    },
    deleteMe(){
      const mission = this.deleteDialog.mission;
        this.$store.dispatch("deleteMission", mission);
    },
  }
};
</script>
<style lang="sass">
.v-card__title
  word-break: normal !important
</style>