<template lang="pug">
  div
    v-dialog(v-model='dialog' persistent='' max-width='600px')
      template(v-slot:activator='{ on }')
        v-btn(depressed='' color='primary' v-on='on')
          v-icon(left='') mdi-plus
          |           Neues Programm
      v-card
        v-card-title
          span.headline Neues Programm
        v-card-text
          v-container
            v-text-field(outlined='' v-model='program.name' label='Name' required='')
            v-textarea(outlined='' name='input-7-4' label='Beschreibung' v-model='program.description')
        v-card-actions
          v-spacer
          v-btn(:disabled='loading' :loading='loading' text='' @click='dialog = false') Abbrechen
          v-btn(depressed='' color='primary' :disabled='loading' :loading='loading' @click='createProgram') Programm erstellen

</template>
<script>

export default {
  data: () => ({
    dialog: false
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user
    },
    program: {
      get() {
        return this.$store.getters.program || "";
      },
      set(value) {
        this.$store.commit("setProgram", value);
      }
    }
  },
  methods: {
    createProgram() {
      this.$store.commit("setLoading", true);
      const payload = {
        description: this.program.description,
        name: this.program.name,
        createdAt: new Date(),
        createdBy: {
                displayName: this.user.displayName,
                uid: this.user.uid
              }
      };
      this.$store.dispatch("createProgram", payload);
      this.dialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100% !important;
  border-radius: 4px;
}
</style>
