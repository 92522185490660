<template lang="pug">
    v-dialog(v-model='scheduleMissionDialog' width='650px')
      template(v-slot:activator='{ on }')
        v-btn(color="primary lighten-1" v-if="variables.scheduledAt" :disabled="variables.notified || loading" text v-on='on') 
          v-icon(left v-html="variables.notified ? 'alarm_on' : 'alarm'") 
          | {{fromNow(variables.scheduledAt.toDate())}}
        v-btn(outlined color="primary lighten-1" v-else :disabled="variables.notified || loading" text v-on='on') 
          v-icon(left) insert_invitation
          | Jetzt Terminieren
      v-card
        v-toolbar(dark color="primary" flat) 
          v-toolbar-title Terminiere die Mission
        v-card-text
          v-row
            v-col(cols="12" md="6")
                v-menu(ref='date_menu' v-model='menu' :close-on-content-click='false' :return-value.sync='date_picker' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on }')
                    v-text-field(v-model='computedDateFormattedMomentjs' label='Datum' prepend-icon='event' readonly='' v-on='on')
                  v-date-picker(v-model='date_picker' @input="$refs.date_menu.save(date_picker)" locale="de" no-title='' scrollable='')
            v-col(cols="12" md="6")
              v-menu(ref='time_menu' v-model='menu2' :close-on-content-click='false' :nudge-right='40' :return-value.sync='time_picker' transition='scale-transition' offset-y='' max-width='290px' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(v-model='time_picker' label='Zeit' prepend-icon='access_time' readonly='' v-on='on')
                v-time-picker(v-if='menu2' v-model='time_picker' format="24hr" full-width='' @click:minute='$refs.time_menu.save(time_picker)')
        v-card-actions
          v-spacer
          v-btn(text='' @click="scheduleMissionDialog = false") Abbrechen
          v-btn(color='primary' depressed :loading="loading" :disabled="loading" @click='scheduleMission')
            | Terminieren
            
              
        

</template>
<script>
import moment from 'moment'
export default {
    data: () => ({
      scheduleMissionDialog: false,
      date_picker: new Date().toISOString().substr(0, 10),
      time_picker: null,
      menu: false,
      menu2: false,
   }),
   props: ['variables'],
   created() {
     if(this.variables.scheduledAt){
       this.date_picker = this.variables.scheduledAt.toDate().toISOString().substr(0, 10)
       this.time_picker = moment(this.variables.scheduledAt.toDate()).format('LT')
     }
   },
   computed: {
    ugid(){
        return this.$route.params.ugid;
    },
    loading() {
      return this.$store.getters.loading;
    },
    computedDateFormattedMomentjs () {
        
        return this.date_picker ? moment(this.date_picker).format('ll') : ''
      },
  },
  methods: {
    scheduleMission(){
        const data = {
            ugid: this.ugid,
            pid: this.variables.pid,
            mid: this.variables.mid,
            scheduledAt: new Date(this.date_picker + " " + this.time_picker + ":00")
        }
        this.$store.dispatch("scheduleMission", data).then(() =>{
          this.scheduleMissionDialog = false;
        });
       
    },
    fromNow(createdAt) {

      
      if (createdAt) {
        return moment(createdAt).format('lll');
      } else {
        return "";
      }
    },
  }
}
</script>