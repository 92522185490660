<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <template v-slot:activator="{ on }">
        <v-btn depressed color="primary" v-on="on">
          <v-icon left>mdi-plus</v-icon>
          Mitarbeiter hinzufügen
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Mitarbeiter hinzufügen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              outlined
              v-model="employee.email"
              :rules="emailRules"
              label="Email"
              type="email"
              required
            ></v-text-field>
           
                <v-text-field
              outlined
              type="text"
              v-model="employee.firstname"
              label="Vorname"
            ></v-text-field>
            
                <v-text-field
              outlined
              type="text"
              v-model="employee.lastname"
              label="Nachname"
            ></v-text-field>


       

             <v-list shaped>
      
      <v-list-item-group color="primary" v-if="employee.email || employee.firstname || employee.lastname">
        <v-list-item
        >
          <v-list-item-avatar v-if="employee.firstname && employee.lastname">
          <v-avatar color="accent" size="62">
      <span class="white--text title text-uppercase">{{employee.firstname.charAt(0)}}{{employee.lastname.charAt(0)}}</span>
    </v-avatar>
        </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-capitalize" v-if="employee.firstname && employee.lastname">{{employee.firstname}} {{employee.lastname}}</v-list-item-title>
            <v-list-item-subtitle v-if="employee.email">{{employee.email}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
          <v-checkbox
          :disabled="!employee.email || !employee.firstname || !employee.lastname"
      v-model="checkbox"
    ></v-checkbox>
        </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loading"
            text
            @click="dialog = false"
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            :disabled="loading || !checkbox"
            :loading="loading"
            @click="createEmployee"
            >Einladung verschicken</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  data: () => ({
    dialog: false,
    checkbox: false,
    emailRules: [
        v => !!v || 'E-Mail muss gesetzt werden',
        v => /.+@.+/.test(v) || 'E-Mail muss im richtigen Format sein',
    ],
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    employee: {
      get() {
        return this.$store.getters.employee || "";
      },
      set(value) {
        this.$store.commit("updateEmployee", value);
      }
    }
  },
  methods: {
    createEmployee() {
      this.$store.commit("setLoading", true);
      const payload = {
        email: this.employee.email.replace(/\s/g, ""),
        firstname: this.employee.firstname,
        lastname: this.employee.lastname
      };
      this.$store.dispatch("createEmployee", payload).then(() => {
        this.dialog = false;
      }).catch(() => {
        this.$store.commit("setLoading", false);
      });
      
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100% !important;
  border-radius: 4px;
}
</style>
