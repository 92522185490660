import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from "./store";
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import AlertCmp from './components/Shared/Alert.vue';

import UUID from 'vue-uuid';
Vue.use(UUID);

Vue.config.productionTip = false

Vue.component('app-alert', AlertCmp)
var firebaseConfig = {}
if (process.env.NODE_ENV === 'production') {
  //Production
  firebaseConfig = {
    apiKey: "AIzaSyBUmoqDBEo7YSCM88jA3Q2QX0thKmOI9ZU",
    authDomain: "calumis-prod.firebaseapp.com",
    databaseURL: "https://calumis-prod.firebaseio.com",
    projectId: "calumis-prod",
    storageBucket: "calumis-prod.appspot.com",
    messagingSenderId: "881611942984",
    appId: "1:881611942984:web:a472f278f75f7e73667c7d"
  };
} else {
  //Staging
  firebaseConfig = {
    apiKey: "AIzaSyA7KJ6q4_CJFszufvzcxg_dCr7PpXbjxmo",
    authDomain: "calumis-staging.firebaseapp.com",
    databaseURL: "https://calumis-staging.firebaseio.com",
    projectId: "calumis-staging",
    storageBucket: "calumis-staging.appspot.com",
    messagingSenderId: "966214280529",
    appId: "1:966214280529:web:362623ec1ed97fad085f70",
    measurementId: "G-9SSBK8QYJP"
  };
}


firebase.initializeApp(firebaseConfig);
// firebase.analytics();

let app;
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult()
      .then(({ claims }) => {
        user["customClaims"] = claims
        store.commit('setUser', user)
      });
  } else {
    store.commit('setUser', user)
  }



  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});

export const db = firebase.firestore();
export const functions = firebase.app().functions('europe-west1');