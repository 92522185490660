<template lang="pug">
  div
    ask-for-permission-dialog(v-model="deleteDialog")
    v-container
      v-row
        v-col(cols='12')
          div(v-if='error')
            v-card-title {{ error }}
          create-new-employee(v-else='')
        v-col(cols='12', sm='6', md='4', v-for='i in 3', :key='i')
          v-card(v-if='loading && employees.length == 0')
            v-skeleton-loader.mx-auto(type='list-item-avatar-two-line')
        v-col(cols='12', sm='6', md='4', v-for='(employee, index) in employees', :key='employee.id')
          v-card
              v-list-item
                v-list-item-avatar(color='accent')
                  span.white--text.title.has-text-centered(v-if='\
                  employee.customClaims && employee.customClaims.initials\
                  ') {{ employee.customClaims.initials }}
                v-list-item-content
                  v-list-item-title.text-capitalize(v-text='employee.displayName')
                  v-list-item-subtitle(v-text='employee.email')
                v-list-item-action
                  v-btn(icon='', disabled='')
                    v-icon(color='primary', v-text="\
                    employee.emailVerified ? 'verified_user' : 'update'\
                    ") update
              v-card-actions
                
                v-switch(v-model='employee.customClaims.admin', :disabled="!employee.emailVerified || loading", @change="setAdminStatusOfEmployee(index)" , label='Admin')
                v-spacer
                v-btn(icon color="grey" :disabled="loading" @click="removeEmployee(index)")
                  v-icon delete

</template>

<script>
import CreateNewEmployee from "@/components/CreateNewEmployeeDialog.vue";
import AskForPermissionDialog from "@/components/AskForPermissionDialog.vue";
import moment from "moment";
export default {
  name: "employees",
  components: {
    CreateNewEmployee,
    AskForPermissionDialog
  },
  data: () => ({
    deleteDialog: {
      show: false
    },
  }),
  computed: {
    employees() {
      return this.$store.getters.employees || [];
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    getEmployees() {
      this.$store.dispatch("fetchEmployees");
    },
    convertcreatedAt(createdAt) {
      moment.locale("de");
      return moment(createdAt.toDate()).fromNow();
    },
    setAdminStatusOfEmployee(index){
      const data = {
        uid: this.employees[index].uid,
        setAdmin: this.employees[index].customClaims.admin
      }
      this.$store.dispatch('setAdminStatusOfEmployee', data)
    },
    removeEmployee(index){
       this.deleteDialog = {
        show: true,
        text: "Möchtest du den Mitarbeiter '" + this.employees[index].displayName + "' wirklich entfernen?",
        accepted: false,
        button: "Entfernen",
        user: this.employees[index]
      };
    },
    deleteMe(){
      const data = {
        uid: this.deleteDialog.user.uid
      }
      this.$store.dispatch('removeEmployee', data)
    }
  }
};
</script>
<style lang="sass"></style>
