<template lang="pug">
  v-app
    div(v-if='user')
      top-navigation
      v-main
        router-view
    v-main(v-else-if='auth')
      router-view
    div(v-else='')
      v-app-bar(clipped-left='' app='' dark='' flat='' tile prominent extended color='primary lighten-1')
        v-spacer
        v-btn(v-if="username" text='' disabled)
          span {{username}}
          v-icon(right='') person
      v-main(style="padding-bottom: 100px")
        router-view(style="margin-top: -64px; z-index: 1000")
        v-alert.elevation-12.text-center.mx-auto.mt-9(v-if="error" max-width="750") {{error}}
      v-footer(dark='' padless='' absolute='')
        v-card.primary.lighten-1.white--text.text-center(flat='' tile='' width='100%')
          v-divider
          v-card-text.white--text
            | {{ new Date().getFullYear() }} &mdash; 
            strong calumis
            v-spacer
    snackbar
</template>

<script>
const default_layout = "default";
import TopNavigation from "@/components/TopNavigation.vue";
import ClientView from "@/components/ClientView.vue";
import Snackbar from "@/components/Shared/Snackbar.vue";

export default {
  name: "App",

  components: {
    TopNavigation,
    Snackbar,
    ClientView
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "layout";
    },
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    auth() {
      if (this.$route.name == "login" || this.$route.name == "setpassword") {
        return true;
      }
      return false;
    },
    username(){
      return this.$route.query.username;
    }
  }
};
</script>

<style lang="sass">
$box-shadow: 0 0 34px rgba(10, 10, 10,0.07)
.v-card
  box-shadow: $box-shadow
.v-card--flat
  box-shadow: none !important
</style>
