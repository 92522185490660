<template lang="pug">
    v-dialog(v-model='dialog.show' max-width='290')
        v-card
            v-card-title.headline Bist Du Dir sicher?
            v-card-text {{dialog.text}}
            v-card-actions
                v-spacer
                v-btn(color='grey' text='' @click='cancel')
                    | Abbrechen
                v-btn(color='red' text='' @click='accept')
                    | {{dialog.button || "Löschen"}}


</template>

<script>
export default {
  name: "ask-for-permission-dialog",
  props: ["value"],
  data() {
    return {};
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.dialog.show = false;
      this.dialog.accepted = false;
    },
    accept() {
      this.dialog.show = false;
      this.dialog.accepted = true;
      this.$parent.deleteMe();
    },
  },
};
</script>
