<template lang="pug">
div
  v-dialog(v-model='dialog', persistent='', max-width='600px')
    template(v-slot:activator='{ on }')
      v-btn(icon='', color='primary', v-on='on', v-if='edit')
        v-icon(left='') edit
      v-btn(depressed='', color='primary', v-on='on', v-else='')
        v-icon(left='') mdi-plus
        |           Neue Nutzergruppe
    v-card
      v-card-title
        span.headline Neue Nutzergruppe
      v-card-text
        v-container
          v-text-field(outlined='', v-model='usergroup.name', label='Name', required='')
          v-textarea(outlined='', name='input-7-4', label='Beschreibung', v-model='usergroup.description', required='')
      v-card-actions
        v-spacer
        v-btn(:disabled='loading', :loading='loading', text='', @click='dialog = false') Abbrechen
        v-btn(depressed='', color='primary', v-if='edit', :disabled='loading || (!usergroup.name || !usergroup.description)', :loading='loading', @click='updateUsergroup') &Auml;nderungen speichern
        v-btn(depressed='', color='primary', v-else='', :disabled='loading || (!usergroup.name || !usergroup.description)', :loading='loading', @click='createUsergroup') Nutzergruppe erstellen

</template>
<script>

export default {
  data: () => ({
    dialog: false
  }),
  props: ['edit'],
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user
    },
    usergroup: {
      get() {
        return this.$store.getters.usergroup || "";
      },
      set(value) {
        this.$store.commit("updateUsergroup", value);
      }
    }
  },
  methods: {
    createUsergroup() {
      this.$store.commit("setLoading", true);
      const payload = {
        description: this.usergroup.description,
        name: this.usergroup.name,
        participantCount: 0,
        programCount: 0,
        createdAt: new Date(),
        createdBy: {
          displayName: this.user.displayName,
          uid: this.user.uid
        }
      };
      this.$store.dispatch("createUsergroup", payload);
      this.dialog = false;
    },
    updateUsergroup() {
      this.$store.commit("setLoading", true);
      const payload = {
        id: this.$route.params.ugid,
        description: this.usergroup.description,
        name: this.usergroup.name,
        updatedAt: new Date(),
      };
      this.$store.dispatch("updateUsergroup", payload);
      this.dialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 100% !important;
  border-radius: 4px;
}
</style>
