<template lang="pug">
    v-dialog(v-model='addProgramDialog' width='650px')
      template(v-slot:activator='{ on }')
        v-btn.mb-3(block color="accent lighten-1" depressed large v-on='on' @click="searchPrograms" :loading="loading" :disabled="loading") 
            v-icon(left) post_add
            span Programm hinzufügen
      v-card
        v-toolbar(color="primary" dark flat)
          v-toolbar-title Programm hinzufügen
        v-card-text.mt-2 
          v-list-item-group(flat='' v-model='selectedProgram' subheader='' three-line='')
              v-list-item(v-for="(program, index) in programs" :key="program.id" :value="program.id ? program.id : index")
                template(v-slot:default='{ active, toggle }')
                  v-list-item-content
                    v-list-item-title {{program.name}}
                    v-list-item-subtitle {{program.description}}
                  v-list-item-action
                    v-checkbox(v-model='active' @click="toggle")
        v-card-actions
          v-btn(text @click="cancelDialog") Abbrechen
          v-spacer
          v-btn(color="primary" depressed @click="assignProgramToUsergroup") Hinzufügen
 

</template>
<script>

export default {
    data: () => ({
      addProgramDialog: false,
      selectedProgram: null,
   }),

   computed: {
    programs() {
      
       return this.$store.getters.programs || []
    },
    ugid(){
        return this.$route.params.ugid;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    cancelDialog(){
      this.selectedProgram = [];
      this.addProgramDialog = false;
    },
    searchPrograms() {
      this.$store.dispatch("fetchPrograms");
    },
    assignProgramToUsergroup(){
        const data = {
          pid : this.selectedProgram,
          ugid : this.ugid
        }
        
        this.$store.dispatch("assignProgramToUsergroup", data);
        this.selectedProgram = null;
        this.addProgramDialog = false;
    }
  }
}
</script>