<template>
<div>
  <v-snackbar v-model="show">
    {{message}}
    <v-btn text color="accent" @click.native="show = false">Okay</v-btn>
  </v-snackbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
     
    message: {
      get() {
        return this.$store.getters.snack.message || '';
      }
    },
    show: {
      get() {
        return this.$store.getters.snack.show || '';
      },
      set() {
        this.$store.commit('setSnack', '')
      },
    },
  },
//   created() {
//     this.$store.watch(state => state.snack, () => {
        
//       const msg = this.$store.state.snack
//       if (msg !== '') {
//         this.show = true
//         this.message = this.$store.state.snack
//         this.$store.commit('setSnack', '')
//       }
//     })
//   }
}
</script>