<template lang="pug">
  div 
    v-container 
      v-row 
        v-col(cols="12")
          v-expansion-panels(flat dark)
            v-expansion-panel
              v-expansion-panel-header(color="primary") Einstellungen
              v-expansion-panel-content(light color="white")
                v-card(flat light)
                  v-card-text(v-if="messageSettings")
                    v-row 
                      v-col(cols="12" md="4")
                        v-subheader.pl-0 Auto-reply in {{messageSettings.autoReplyAfterDays}} Tagen
                        v-slider(v-model='messageSettings.autoReplyAfterDays', color="accent" @change="settingsChanged = true" persistent-hint step="1" ticks="always" :max="14"  thumb-label='')
                        v-switch(label="Autoreply"  v-model="messageSettings.autoReplyActive" @change="settingsChanged = true")
                      v-col(cols="12" md="8")
                        v-textarea(filled label="Auto-reply Text" @change="settingsChanged = true" v-model="messageSettings.autoReplyText")
                  v-card-actions 
                    v-spacer
                    v-btn.ml-4(color="primary" depressed :loading="loading" :disabled="loading || !settingsChanged" @click="updateMessageSettings") Speichern
        v-col(cols="12")
          v-toolbar(color="grey lighten-4" flat)
            
            v-menu(offset-y=true)
              template(v-slot:activator='{ on: menu, attrs }')
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on: tooltip }')
                    v-btn.mr-2(color='primary' outlined  small v-bind='attrs' v-on='{ ...tooltip, ...menu }')
                      | {{filter.answeredMessages == undefined ? "Alle Nachrichten" : filter.answeredMessages ? "Beantwortet" : "Unbeantwortet" }}
                      v-icon(right) keyboard_arrow_down
                  span Filter nach schon beantworteten oder unbeantworteten Nachrichten
              v-list
                v-list-item(v-for="filter in readUnreadFilters" :key="filter.value" @click="selectReadUnreadFilter(filter)")
                  v-list-item-title {{filter.name}}
              
            v-menu(:close-on-content-click="false" v-model="usergroupMenu" offset-y=true max-width="450")
              template(v-slot:activator='{ on: menu, attrs }')
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on: tooltip }')
                    v-btn.mr-2(color='primary' outlined small v-bind='attrs' v-on='{ ...tooltip, ...menu }')
                      | {{filter.usergroup == undefined ? "Nutzergruppe" : `Nutzergruppe: ${truncate(filter.usergroup.name, 10)}` }}
                      v-icon(right) keyboard_arrow_down
                  span Nach einer Nutzergruppe filtern
              v-card
                v-card-text
                  v-autocomplete( v-model="filter.usergroup" :items="usergroups" clearable hide-details  item-text="name" label="Nutzergruppe" small-chips chip-clearable outlined @click:append-outer="usergroupMenu = false" append-outer-icon="check_circle" return-object @blur="getMessages()")
              
              

            v-menu(:close-on-content-click="false" v-model="userMenu" close-on-click=false offset-y=true max-width="450")
              template(v-slot:activator='{ on: menu, attrs }')
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on: tooltip }')
                    v-btn(color='primary' outlined small v-bind='attrs' v-on='{ ...tooltip, ...menu }')
                      | {{filter.selectedUsers.length > 0 ? `(${filter.selectedUsers.length}) Nutzer` : 'Nach Nutzer filtern' }}
                      v-icon(right) keyboard_arrow_down
                  span Nach Nutzer filtern
              v-card
                v-card-text
                  v-autocomplete( v-model="filter.selectedUsers" :items="allUsers" autofocus clearable hide-details multiple :item-text="userPreview" label="Nutzer" small-chips chip-clearable outlined @click:append-outer="selectingUsersDone()" append-outer-icon="check_circle" return-object @blur="selectingUsersDone()")
            v-spacer
            v-btn(@click="clearAllFilters()" v-if="filter.usergroup != undefined || filter.selectedUsers.length > 0 || filter.answeredMessages != undefined" color="primary" depressed small) Zurücksetzen
            
           
      v-row 
        v-col(cols="12" v-if="messages && messageSettings")
          v-expansion-panels(focusable, flat)
            v-expansion-panel(v-for="message in messages" :key="message.id")
              v-expansion-panel-header(disable-icon-rotate, color="grey lighten-4")
                  template(v-slot:actions='')
                      v-chip.ma-2(outlined v-if="message.mission.backOfficeName")
                        v-avatar(left='')
                            v-icon  assignment
                        | {{message.mission.backOfficeName}}
                      v-chip.ma-2(v-if="replyStatus(message) == 'success'" color='green' text-color='white')
                        v-avatar(left='')
                            v-icon(color="white") Gesendet
                        | {{ fromNow(message.feedback.sentAt) }}
                      v-chip.ma-2(v-else-if="!messageSettings.autoReplyActive" outlined color='warning')
                          v-avatar(left='')
                              v-icon(color="warning") warning
                          | Auto-reply deaktiviert
                      v-chip.ma-2(v-else-if="replyStatus(message) == 'planed'" outlined color='primary')
                          v-avatar(left='')
                              v-icon(color="primary") alarm
                          | {{ autoReply(message) }}
                      v-chip.ma-2(v-else-if="replyStatus(message) == 'warning'" color='warning' text-color='white')
                          v-avatar(left='')
                              v-icon(color="white") warning
                          | {{ fromNow(message.answer.receivedAt) }}
                      
                  | {{message.participant.username || message.participant.email}}
              v-expansion-panel-content(color="grey lighten-4")
                v-row
                  v-col.pa-0.mt-4(cols="12" md="8")
                    v-alert.float-left.elevation-20(border='left' colored-border='' color='primary' elevation='0')
                        h6(style="color: #444") {{fromNow(message.answer.receivedAt)}}
                        span {{message.answer.text}}
                  v-col.pa-0(cols="12" md="8" offset-md="4")
                    v-alert.float-right.elevation-20(v-if="message.feedback.sentAt != null" border='right' colored-border='' color='accent' elevation='0')
                        h6.text-right.align-end(style="color: #444") {{fromNow(message.feedback.sentAt)}}
                        span {{message.feedback.text}}
                  v-col.pa-0(cols="12")
                    div.pl-1.text-right.float-none(v-if="message.feedback.sentAt != null" colored-border='' color='grey' elevation='0')
                        span.overline(v-if="message.feedback.type == 'manual' && message.employee.displayName != ''") Versendet von {{message.employee.displayName}}
                        span.overline(v-if="message.feedback.type != 'manual'") Automatisch versendet worden
                    

                    v-textarea.mt-4(v-else clearable rows="3" outlined=''  label='Antwort...' v-model="message.feedback.text" no-resize  append-icon='send' @click:append="sendFeedback(message)")
        v-col(cols="12" v-if="messages.length > 9")
          v-btn(@click="nextMessages(page + 1)" depressed :disabled="error === 'ende'") Mehr laden
        v-col(cols="12" v-if="messages.length == 0")
          v-alert(type="warning" color="grey" outlined border="left") Es wurden keine Nachrichten gefunden
    v-dialog(v-model="sendFeedbackDialog" :persistent="loading" width="500")                
      v-card(v-if="sendFeedbackPayload")
        v-card-title( primary-title='')
          | Feedback an {{sendFeedbackPayload.message.participant.username}}
        v-card-text.pt-4
          | {{sendFeedbackPayload.text}}
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='grey', text, @click='sendFeedbackDialog = false')
            | Abbrechen
          v-btn(color='primary', depressed, :disabled="loading" :loading="loading" @click='confirmSendFeedback')
            | Senden


</template>

<script>
import moment from "moment";
export default {
  name: "messages",
  data: () => ({
    sendFeedbackPayload: null,
    sendFeedbackDialog: false,
    settingsChanged: false,
    page: 0,
    filter: {
      answeredMessages: undefined,
      usergroup: undefined,
      selectedUsers: [],
    },
    readUnreadFilters: [
      { value: undefined, name: "Alle" },
      { value: true, name: "Beantwortet" },
      { value: false, name: "Unbeantwortet" },
    ],
    filterType: "Alle",
    usergroupMenu: false,
    userMenu: false,
  }),
  computed: {
    messages() {
      return this.$store.state.messages.messages ?? [];
    },
    messageSettings() {
      return this.$store.getters.messageSettings;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    usergroups() {
      return this.$store.getters.usergroups || [];
    },
    allUsers() {
      return this.$store.getters.allUsers || [];
    },
  },
  mounted() {
    this.getMessages();
    this.getUsergroups();
    this.getAllUsers();
  },
  methods: {
    getMessages() {
      const filter = {
        answeredMessages: this.filter.answeredMessages,
        usergroup: this.filter.usergroup,
        selectedUsers: this.filter.selectedUsers,
      };
      this.$store.dispatch("fetchMessages", {
        offset: 0,
        filter,
      });

      this.$store.dispatch("fetchMessageSettings");
    },
    getUsergroups() {
      this.$store.dispatch("fetchUsergroups");
    },
    getAllUsers() {
      this.$store.dispatch("fetchUniqueUsers");
    },
    userPreview: (item) => item.email,
    nextMessages(page) {
      this.page = page;
      this.$store.dispatch("fetchMessages", {
        offset: page,
        filter: this.filter,
      });
    },
    sendFeedback(message) {
      var payload = {
        message: message,
        text: message.feedback.text,
        id: message.id,
      };
      this.sendFeedbackPayload = payload;
      this.sendFeedbackDialog = true;
    },
    confirmSendFeedback() {
      this.$store
        .dispatch("sendFeedback", this.sendFeedbackPayload)
        .then(() => {
          this.sendFeedbackDialog = false;
          this.sendFeedbackPayload = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateMessageSettings() {
      this.settingsChanged = false;
      this.$store.dispatch("updateMessageSettings", this.messageSettings);
    },
    fromNow(timestamp) {
      if (timestamp) {
        moment.locale("de");
        return moment(timestamp.toDate()).format("lll");
      } else {
        return "";
      }
    },
    replyStatus(message) {
      const receivedAt = message.answer.receivedAt;
      const sentAt = message.feedback.sentAt;

      if (this.messageSettings) {
        const autoReplyAfterDays = this.messageSettings.autoReplyAfterDays || 0;
        const scheduledAt = moment(receivedAt.toDate()).add(
          autoReplyAfterDays,
          "days"
        );
        if (sentAt != null) {
          // Alles hat geklappt
          return "success";
        } else if (scheduledAt < moment() && sentAt == null) {
          // warning war geplant aber wurde nichts verschickt
          return "warning";
        } else {
          // In vier tagen wird verschickt
          return "planed";
        }
      } else {
        return "";
      }
    },
    autoReply(message) {
      const receivedAt = message.answer.receivedAt;
      const autoReplyAfterDays = this.messageSettings.autoReplyAfterDays || 0;
      const scheduledAt = moment(receivedAt.toDate()).add(
        autoReplyAfterDays,
        "days"
      );
      moment.locale("de");
      return scheduledAt.calendar();
    },
    clearAllFilters() {
      this.filter.answeredMessages = undefined;
      this.filter.usergroup = undefined;
      this.filter.selectedUsers = [];
      this.getMessages();
    },
    selectReadUnreadFilter(filter) {
      this.filter.answeredMessages = filter.value;
      this.getMessages();
    },
    truncate(str, n) {
      return str && str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    selectingUsersDone() {
      this.userMenu = false;
      this.getMessages();
    },
  },
};
</script>

<style lang="sass">
.v-expansion-panel
  border-bottom: 1px solid #eee !important
.v-expansion-panel-header--active
  &:hover::before, &::before
    opacity: 0.03 !important
</style>
