import { db } from "@/main";

export default {
  state: {
    settings: [],
    messageSettings: null,
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setMessageSettings(state, payload) {
      state.messageSettings = payload;
    },
  },
  actions: {
    fetchSettings({ commit }) {
      const docRef = db.collection("settings");
      docRef
        .get()
        .then((querySnapshot) => {
          var settings = [];
          querySnapshot.forEach(function(doc) {
            settings.push(doc.data());
          });
          commit("setSettings", settings);
          commit("setError", null);
        })
        .catch((error) => {
          console.log(error);
          commit("setSnack", "");
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    fetchMessageSettings({ commit }) {
      const docRef = db.collection("settings").doc("messages");
      docRef
        .get()
        .then((doc) => {
          var item = doc.data();
          item["id"] = doc.id;
          commit("setLoading", false);
          commit("setError", null);
          commit("setMessageSettings", item);
        })
        .catch((error) => {
          console.log(error);
          commit("setSnack", "");
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    updateMessageSettings({ commit }, payload) {
      payload.autoReplyAfterDays = Number(payload.autoReplyAfterDays);
      commit("setLoading", true);
      const docRef = db.collection("settings").doc("messages");
      docRef
        .set(payload, { merge: true })
        .then(() => {
          commit("setLoading", false);
          commit("setError", null);
          commit("setSnack", "Einstellungen wurden gespeichert!");
        })
        .catch((error) => {
          console.log(error);

          commit("setLoading", false);
          commit("setSnack", "");
          commit(
            "setError",
            "Leider ist etwas schiefgelaufen. Versuche es später noch einmal."
          );
        });
    },
  },
  getters: {
    settings(state) {
      return state.settings;
    },
    messageSettings(state) {
      return state.messageSettings;
    },
  },
};
