<template lang="pug">
  .settings
    v-container
      v-row
        v-col(cols='12' md="8")
          v-card
            .d-flex.flex-no-wrap.justify-space-between
              div
                v-card-title.headline(v-text='user.displayName')
                v-card-subtitle(v-text='user.email')
                v-card-actions
                  v-btn(text='' @click='dialog = true') Bearbeiten
              v-avatar.ma-3(size='125' tile='' color="accent")
                span.headline.white--text(v-if="user.customClaims && user.customClaims.initials") {{user.customClaims.initials}}
            
          //-v-card.mx-auto
            v-list-item(three-line='' v-if='user')
              v-list-item-content
                v-list-item-title.headline {{ user.displayName }}
                v-list-item-subtitle {{ user.email }}
        v-col(cols="12" md="4")
          v-card.mx-auto
            v-card-text
              v-list-item
                v-list-item-content
                  v-list-item-title {{toDate(user.lastLoginAt)}}
                  v-list-item-subtitle Letzter Login
              v-list-item
                v-list-item-content
                  v-list-item-title {{toDate(user.createdAt)}}
                  v-list-item-subtitle Eingeladen
            v-card-actions
              
              v-btn(dark color='grey darken-1' text  @click='deleteDialog = true') Mitgliedschaft beenden

    v-dialog(v-model='dialog' v-if='user' persistent='' max-width='600px')
      v-card
        v-card-title
          span.headline(v-if='$route.query.edit_user') Profil vervollst&auml;ndigen
          span.headline(v-else='') Mitarbeiter Profil
        v-card-text
          v-container
            v-row
              v-col(cols='12')
                v-text-field(label='Voller Name' v-model='displayName')
        v-card-actions
          v-spacer
          v-btn(text='' @click='dialog = false') Abbrechen
          v-btn(text='' @click='updateUser') Speichern
    v-dialog(v-model='deleteDialog' persistent='' max-width='290')
      v-card
        v-card-title.headline Mitgliedschaft beenden
        v-card-text Bist Du Dir sicher, dass Du deine Mitgliedschaft beenden möchtest? Alle Information von Dir werden gelöscht.
        v-card-actions
          v-spacer
          v-btn(text='' @click='deleteDialog = false') Abbrechen
          v-btn(color='grey darken-1' text='' @click='deleteUser') Löschen

</template>

<script>
// import { mapGetters } from "vuex";
import * as firebase from "firebase/app";
import moment from "moment";

export default {
  name: "settings",
  data: () => ({
    dialog: false,
    deleteDialog: false,
    authResponse: null,
  }),
  methods: {
    updateUser() {
      this.$store.dispatch("setUserInformations", this.user);
      this.dialog = false;
    },
    deleteUser() {
      const user = firebase.auth().currentUser;
      const self = this;
      user
        .delete()
        .then(function() {
          self.$router.replace({ name: "login" });
        })
        .catch(function(error) {
          alert(error);
        });
    },
    toDate(createdAt) {
        
     
        var date = moment(createdAt).locale("de").format("llll");

      
      return date;
    },
    
    
  },
  created() {
    const edit_user = this.$route.query.edit_user;
    if(edit_user){
      this.dialog = true;
    }
    
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    
    displayName: {
      get() {
        return this.$store.getters.user.displayName || "";
      },
      set(value) {
        this.$store.commit("updateDisplayName", value);
      }
    }
  }
};
</script>
<style></style>
