<template lang="pug">
  .login.fill-height
    v-container.fill-height
      .overlay
      v-row(align='center', justify='center')
        v-col(cols='12', sm='6')
          v-alert.mb-4(v-if='error', dismissible='')
            span(v-if='error.message') {{ error.message }}
            span(v-else='') {{ error }}
          v-card.elevation-12.pb-12(width='100%', color='white')
            v-toolbar(flat='', color='primary py-1', height='100')
              img.ml-8(src='~@/assets/logo-calumis.png', style='max-width: 250px;', alt='calumis logo')
            v-form.px-md-8.pt-8.pb-2(ref='form', v-model='valid', @submit.prevent='onSignin', lazy-validation='')
              v-card-text
                v-text-field(v-model='email', label='E-Mail', type='email', required='', :rules='emailRules')
                v-text-field#password(v-if='!hidePassword', v-model='password', :rules='passwordRules', label='Passwort', type='password', required='')
              v-card-actions
                v-row
                  v-col
                    v-btn.text-capitalize(v-if="!hidePassword"  text='', small='', :disabled='loading', @click='hidePassword = true') Passwort vergessen?
                    v-btn.text-capitalize(v-else  text='', small='',  @click='hidePassword = false') Zurück zum Login
                  v-spacer
                    v-col
                      v-btn.text-capitalize.px-4.float-md-right(color='accent', rounded='', depressed='', :disabled='loading || email.length < 1', :loading='loading', @click="onResetPassword" v-if="hidePassword" ) Zurücksetzen 
                      v-btn.text-capitalize.px-4.float-md-right(color='accent', rounded='', depressed='', :disabled='!valid || loading || email.length < 1 || password.length < 8', :loading='loading', v-else,  type='submit') Anmelden
    v-footer
      div &copy; {{ new Date().getFullYear() }} calumis

</template>
<script>
export default {
  name: "login",
  data: () => ({
    valid: false,
    password: "",
    hidePassword: false,
    passwordRules: [
      (v) => !!v || "Bitte setze ein Passwort",
      (v) =>
        (v && v.length >= 8) || "Entschuldigung, Dein Passwort ist zu kurz!",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Bitte trage deine E-Mail ein",
      (v) => /.+@.+\..+/.test(v) || "Das ist kein valides Format",
    ],
    checkbox: false,
    lazy: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  created() {
    if (this.user) {
      this.$router.push("/missions");
    }
  },
  methods: {
    onSignin() {
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password,
      });
    },
    onResetPassword() {
      if (this.email === "") {
        return this.$store.dispatch("setError", {
          message: "Bitte gib deine E-Mail-Adresse an",
        });
      } else {
        this.$store.dispatch("resetPasswordWithEmail", { email: this.email });
      }
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
  // methods: {
  //   login() {
  //     firebase
  //       .auth()
  //       .signInWithEmailAndPassword(this.email, this.password)
  //       .then(() => {
  //         this.$router.replace({ name: "home" });
  //       })
  //       .catch(err => {
  //         this.error = err.message;
  //       });
  //   }
  // }
};
</script>

<style lang="scss">
.login {
  background: url("~@/assets/calumis-background.jpg");
  background-size: cover;
  background-position: center center;
  position: relative;
  margin: 0;
  padding: 0;

  .overlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .v-card {
    width: 100%;
  }
  h2 {
    font-size: 2rem !important;
  }
  span {
    //font-size: 1rem;
    a {
      text-decoration: none;
    }
  }
  .fb-btn {
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
    color: #fff !important;
  }
  .google-btn {
    background-color: #fff !important;
    border-color: #eaeaea !important;
    color: #505050 !important;
  }
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #4b4b4b;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #4b4b4b;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}
</style>
