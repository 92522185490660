<template>
  <div>
    <v-card flat tile>
      <v-toolbar color="primary" dark prominent extended flat>
       
      </v-toolbar>

      
         <router-view></router-view>
      
    </v-card>
     <v-footer
    dark
    padless
    absolute
    
  >
    <v-card
      flat
      tile
      width="100%"
      class="primary lighten-1 white--text text-center"
    >
      

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>calumis</strong>
        <v-spacer></v-spacer>
        
      </v-card-text>
    </v-card>
  </v-footer>
    </div>
</template>
<script>
export default {
    data: () => ({
      icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-google-plus',
        'fab fa-linkedin',
        'fab fa-instagram',
      ],
    }),
  }
</script>
