import { db, functions } from "@/main";
import firebase from "firebase/app";

export default {
  state: {
    messages: [],
    lastMessage: null,
    message: null,
  },
  mutations: {
    setMessages(state, payload) {
      state.messages = payload;
    },
    addToMessages(state, payload) {
      state.messages.push(payload);
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setLastMessage(state, payload) {
      state.lastMessage = payload;
    },
    updateMessage(state, payload) {
      var id = payload.id;
      var elementPos = state.messages
        .map((x) => {
          return x.id;
        })
        .indexOf(id);
      state.messages[elementPos].feedback = payload.feedback;
    },
  },
  actions: {
    fetchMessages({ commit, state }, { offset, filter }) {
      var limit = 10;
      if (state.lastMessage == undefined && offset > 0) {
        commit("setError", "ende");
      } else {
        const { usergroup, answeredMessages, selectedUsers } = filter;
        const filterByUsergroup = !!usergroup;
        const filterByAnsweredMessages = answeredMessages !== undefined;

        var query = db.collection("messages");

        if (filterByAnsweredMessages) {
          if (answeredMessages) {
            query = query
              .where("feedback.sentAt", "!=", null)
              .orderBy("feedback.sentAt", "desc");
          } else {
            query = query.where("feedback.sentAt", "==", null);
          }
        }

        if (filterByUsergroup) {
          query = query.where("usergroup.id", "==", usergroup.id);
        }

        if (selectedUsers && selectedUsers.length > 0) {
          query = query.where("participant.email", "in", selectedUsers);
        }

        query = query.orderBy("answer.receivedAt", "desc");

        if (offset > 0) {
          query = query.startAfter(state.lastMessage).limit(limit);
        } else {
          query = query.limit(limit);
        }
        query
          .get()
          .then((querySnapshot) => {
            var messages = [];

            if (querySnapshot.size == 0) {
              commit("setError", "ende");
            } else {
              commit("setError", null);
            }
            querySnapshot.forEach(function(doc) {
              var item = doc.data();
              item["id"] = doc.id;
              if (offset > 0) {
                commit("addToMessages", item);
              } else {
                messages.push(item);
              }
            });
            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            if (offset == 0) {
              commit("setMessages", messages);
            }
            commit("setLastMessage", lastVisible);
          })
          .catch((error) => {
            console.log(error);

            commit("setSnack", "");
            commit(
              "setError",
              "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
            );
          });
      }
    },
    fetchMessage({ commit }, id) {
      const docRef = db.collection("messages").doc(id);
      docRef
        .get()
        .then((doc) => {
          var item = doc.data();
          item["id"] = doc.id;
          commit("setMessage", item);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          commit("setSnack", "");
          commit("setLoading", false);
          commit(
            "setError",
            "Du hast nicht die benötigten Rechte um diesen Inhalt zu lesen."
          );
        });
    },
    sendFeedback({ commit }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        var data = {
          text: payload.text,
          messageId: payload.id,
        };
        var sendFeedbackToParticipant = functions.httpsCallable(
          "sendFeedbackToParticipant"
        );
        sendFeedbackToParticipant(data)
          .then((result) => {
            var sanitizedMessage = result.data;
            if (sanitizedMessage.feedback.sentAt != null) {
              var formatDate = firebase.firestore.Timestamp.fromDate(
                new Date(sanitizedMessage.feedback.sentAt)
              );
              sanitizedMessage.feedback["sentAt"] = formatDate;
              commit("updateMessage", {
                feedback: sanitizedMessage.feedback,
                id: payload.id,
              });
            }
            commit("setLoading", false);

            commit("setSnack", "Feedback wurde versendet!");
            resolve();
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            commit("setSnack", error.message);
            commit("setLoading", false);
            reject();
          });
      });
    },
    sendMailToUpdateUsername({ commit }, { userId, ugid }) {
      commit("setLoading", true);
      var sendMailToUpdateUsername = functions.httpsCallable(
        "sendMailToUpdateUsername"
      );
      sendMailToUpdateUsername({ userId, ugid })
        .then(() => {
          commit("setLoading", false);
          commit("setSnack", "Nachricht wurde versendet!");
          return;
        })
        .catch((err) => {
          commit("setLoading", false);
          commit("setSnack", "Nachricht konnte nicht versendet werden!");
          return err;
        });
    },
    sendReminderForMission({ commit }, { userId, ugid, mid, username, pid }) {
      commit("setLoading", true);

      var sendMailReminderForMission = functions.httpsCallable(
        "sendMailReminderForMission"
      );
      sendMailReminderForMission({
        userId,
        ugid,
        mid,
        username,
        pid,
      })
        .then(() => {
          commit("setLoading", false);
          commit("setSnack", "Nachricht wurde versendet!");
          return;
        })
        .catch((err) => {
          commit("setLoading", false);
          commit("setSnack", "Nachricht konnte nicht versendet werden!");
          return err;
        });
    },
  },
  getters: {
    messages(state) {
      return state.messages;
    },
    message(state) {
      return state.message;
    },
  },
};
